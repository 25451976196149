import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { TAGS } from 'src/enums/TaggingEnum'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { H1, H3, PMedium, PSmall, SuccessSign } from 'src/styles/commons'
import { formatDayMouth, formatHours } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer, InterUIList, InterUISeparator } from '@interco/inter-ui-react-lib'

export const AppointmentCancellationConfirmed: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const appointment = useSelector(SchedulingActions.getSelectedAppointment)
  const holder = useSelector(SubscriptionActions.getHolder)
  const spontaneousDemand = useSelector(HealthcareActions.getAttendance)
  const scheduling = useSelector(SchedulingActions.getSelectedAppointment)
  const cancellation = useSelector(HealthcareActions.getRedirectToCancel)

  const successIcon = <Icons.CheckCircle width={32} height={32} color={theme.colors.base.white} />

  useBasePage({
    navbarTitle: PageTitles.DR_INTER,
    hideHomeBtn: false,
    hideBackNavigationBtn: true,
  })

  /**
   * Função responsável por controlar o evento de click dos botões
   * do stickyFooter.
   * @param variant Tipo do botão que foi clicado.
   */
  const handleClickStickyFooter = (variant: string) => {
    switch (variant) {
      case 'PRIMARY':
        if (cancellation.type === 'IMMEDIATE') {
          BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_COMPROVANTECANCEL_T_OPCAO.name, {
            content_name: 'Agendar uma consulta',
          })

          BaseBridge.ticWb({
            name: 'btn_schedule_appointment_cancellation_immediate',
            module: 'dr_inter',
            action: 'schedule_appointment_cancellation_immediate',
            params: {
              content_name: 'Agendar uma consulta',
              professionalType: appointment.professional.type,
            },
          })

          dispatch(SchedulingActions.getSpecialtiesListRequest())
          dispatch(
            SchedulingActions.setProfessionalType({
              history,
              pathname: TypesRoutes.SPECIALTIES,
              type: 'DOCTOR',
            }),
          )
        } else {
          BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_COMPROVCANCEL_T_OPCAO.name, {
            content_name: 'Agendar outra consulta',
          })

          if (appointment.professional.type === 'DOCTOR') {
            dispatch(SchedulingActions.getSpecialtiesListRequest())
          } else {
            dispatch(
              SchedulingActions.getProfessionalsListRequest({
                type: appointment.professional.type,
                page: 1,
                patientId: holder.patientId as number,
              }),
            )
          }

          BaseBridge.ticWb({
            name: 'btn_schedule_appointment_cancellation',
            module: 'dr_inter',
            action: 'schedule_appointment_cancellation',
            params: {
              content_name: 'Agendar outra consulta',
              professionalType: appointment.professional.type,
            },
          })

          dispatch(
            SchedulingActions.setProfessionalType({
              history,
              pathname:
                appointment.professional.type === 'DOCTOR'
                  ? TypesRoutes.SPECIALTIES
                  : TypesRoutes.PROFESSIONAL,
              type: appointment.professional.type,
            }),
          )
        }

        break
      case 'SECONDARY':
        BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_COMPROVANTECANCEL_T_OPCAO.name, {
          content_name: 'Home do Doutor Inter',
        })

        BaseBridge.ticWb({
          name: 'btn_home_appointment_cancellation',
          module: 'dr_inter',
          action: 'home_appointment_cancellation',
          params: {
            content_name: 'Home do Doutor Inter',
          },
        })

        history.push(TypesRoutes.START)
        break
      default:
        break
    }
  }

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button onClick={() => handleClickStickyFooter('PRIMARY')}>
        {cancellation.type === 'IMMEDIATE' ? 'Agendar uma consulta' : 'Agendar outra consulta'}
      </Button>
      <Button
        variant="secondary"
        margin="16px 0 0"
        onClick={() => handleClickStickyFooter('SECONDARY')}
      >
        Home do Doutor Inter
      </Button>
    </InterUIContainer>
  )

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_cancel_schedule',
      module: 'dr_inter',
      actions: ['cancellation_reason_schedule_other', 'cancellation_reason_schedule'],
      params: {},
    })
  }, [])

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIContainer margin="40px 24px 32px">
          <SuccessSign>{successIcon}</SuccessSign>
          <H1 margin="40px 0 8px">Você cancelou sua consulta!</H1>
          <PMedium marginBottom="0" scale={400}>
            Com o cancelamento, nenhum valor será cobrado do seu cartão.
          </PMedium>
        </InterUIContainer>

        <InterUISeparator height="8px" />

        <InterUIContainer margin="32px 24px">
          <H3 marginBottom="16px" bold>
            Consulta cancelada
          </H3>
          <InterUIList
            margin="0 0 16px"
            value={
              <PSmall bold marginBottom="0px">
                {cancellation.type === 'IMMEDIATE'
                  ? 'Clinico Geral'
                  : scheduling.professional.specialty}
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Especialidade
              </PSmall>
            }
          />
          <InterUIList
            margin="0 0 16px"
            value={
              <PSmall bold marginBottom="0px">
                {cancellation.type === 'IMMEDIATE'
                  ? formatDayMouth(spontaneousDemand.attendance.queueEntryTime)
                  : formatDayMouth(scheduling.appointmentDate)}
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Data da consulta
              </PSmall>
            }
          />
          <InterUIList
            margin="0 0 16px"
            value={
              <PSmall bold marginBottom="0px">
                {cancellation.type === 'IMMEDIATE'
                  ? formatHours(spontaneousDemand.attendance.queueEntryTime)
                  : formatHours(scheduling.appointmentDate)}
              </PSmall>
            }
            text={
              <PSmall scale={400} marginBottom="0px">
                Horário da consulta
              </PSmall>
            }
          />
        </InterUIContainer>
      </InterUIContainer>
    </>
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { reasonCancellationSubscription } from 'src/mocks/ReasonCancellation'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { ContainerFullPage, H2, PSmall } from 'src/styles/commons'

import { InterUIContainer, InterUIRadio, InterUISeparator } from '@interco/inter-ui-react-lib'

export const CancellationReason: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [reasonValue, setReasonValue] = useState('')

  useBasePage({
    navbarTitle: PageTitles.CANCELLATION,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const renderReason = reasonCancellationSubscription.map((item, index) => {
    const key = `reason-${index}`

    return (
      <InterUIRadio
        key={key}
        id={key}
        data-testid={key}
        radioPosition="right"
        name="reason"
        padding="16px 0"
        value={item.reason}
        checked={reasonValue === item.reason}
        onChange={(e) => setReasonValue(e.target.value)}
        {...(index === reasonCancellationSubscription.length - 1 && { border: 'none' })}
      >
        <PSmall marginBottom="0">{item.reason}</PSmall>
      </InterUIRadio>
    )
  })

  const goToCancellationConditions = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ASSINATURA_CANCEL_T_MOTIVO.name, {
      motivo_cancelamento: reasonValue,
    })

    BaseBridge.ticWb({
      name: 'btn_confirm_cancellation_reasons',
      module: 'dr_inter',
      action: 'confirm_cancellation_reasons',
      params: {
        tag: TAGS.C_DOUTORINTER_ASSINATURA_CANCEL_T_MOTIVO.name,
        motivo_cancelamento: reasonValue,
      },
    })

    dispatch(SubscriptionActions.setReasonCancelSubscription(reasonValue))
    history.push(TypesRoutes.CANCELLATION_CONDITIONS)
  }

  const stickyFooter = (
    <>
      <InterUISeparator height="1px" />
      <Button margin="24px" disabled={!reasonValue} onClick={goToCancellationConditions}>
        Cancelar assinatura
      </Button>
    </>
  )

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_cancellation_reasons',
      module: 'dr_inter',
      actions: ['confirm_cancellation'],
      params: {},
    })
  }, [])

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <ContainerFullPage margin="0 24px">
          <H2 margin="24px 0px 16px">Motivo de cancelamento</H2>

          {renderReason}
        </ContainerFullPage>
      </InterUIContainer>
    </>
  )
}

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Babi, CardSchedule, LoadSchedule } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { filters } from 'src/mocks/Schedules'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { PersistedCacheActions } from 'src/store/ducks/persistedCache/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { IAppointmentDetails } from 'src/store/ducks/scheduling/types'
import { TabActions } from 'src/store/ducks/tab/actions'
import { PSmall } from 'src/styles/commons'

import { InterUIContainer, InterUISeparator, InterUITag } from '@interco/inter-ui-react-lib'

import { ContentFilters } from './Schedule.styles'

export const Schedule: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const appointmentList = useSelector(SchedulingActions.getAppointmentList)
  const isLoading = useSelector(LoadingActions.get)
  const hasAppointmentsListCache = useSelector(PersistedCacheActions.hasAppointmentsListCache)
  const appointmentPathParams = useSelector(PathParamsActions.getAppointmentPathParams)

  const [currentFilter, setCurrentFilter] = useState('ALL')

  useEffect(() => {
    dispatch(TabActions.schedule())

    if (!hasAppointmentsListCache) {
      dispatch(PersistedCacheActions.setAppointmentsListCache())
      dispatch(SchedulingActions.getAppointmentListRequest())
    }
  }, [dispatch, hasAppointmentsListCache])

  useEffect(() => {
    appointmentPathParams === 'SCHEDULED' && setCurrentFilter('PENDING')
  }, [appointmentPathParams])

  const filteredAppointments =
    currentFilter === 'ALL'
      ? appointmentList
      : appointmentList.filter((filter) => filter.status === currentFilter)

  const renderFilters = filters.map((filter, index) => (
    <InterUITag
      key={filter.label}
      id={filter.label}
      variant="action-check"
      type="radio"
      checked={filter.status === currentFilter}
      margin={index !== filters.length - 1 ? '0 8px 0 0' : '0'}
      onChange={() => setCurrentFilter(filter.status)}
    >
      {filter.label}
    </InterUITag>
  ))

  const renderCardsSchedule = filteredAppointments.map((appointment, index) => {
    const key = `card-schedule-${index}`

    return (
      <CardSchedule
        key={key}
        showStatus
        filterStatus={currentFilter}
        appointmentDetails={appointment}
        onClickedDetail={() => onHandleClickedDetail(appointment)}
        data-testid={`card-schedule-${index}`}
      />
    )
  })

  interface routeOptions {
    [key: string]: () => void
  }

  const chooseRoute: routeOptions = {
    PENDING: () => history.push(TypesRoutes.SCHEDULED_DETAILS),
    UNREALIZED: () => history.push(TypesRoutes.UNREALIZED_DETAILS),
    CANCELED: () => history.push(TypesRoutes.CANCELLED_DETAILS),
  }

  const onHandleClickedDetail = (appointmentDetail: IAppointmentDetails) => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_T_CONSULTA.name, {
      content_id: appointmentDetail.status !== undefined ? appointmentDetail.status : '',
      especialidade: appointmentDetail.professional.specialty,
    })
    BaseBridge.ticWb({
      name: 'btn_click_detail',
      module: 'dr_inter',
      action: 'click_detail',
      params: {
        tag: TAGS.C_DOUTORINTER_HOME_T_CONSULTA.name,
        content_id: appointmentDetail.status !== undefined ? appointmentDetail.status : '',
        specialty: appointmentDetail.professional.specialty,
      },
    })

    dispatch(SchedulingActions.setSelectedAppointment(appointmentDetail))

    const redirect = chooseRoute[appointmentDetail.status]
    redirect()
  }

  return !isLoading ? (
    <InterUIContainer margin="0">
      <InterUIContainer margin="0 24px">
        <ContentFilters>{renderFilters}</ContentFilters>

        {appointmentList.length > 0 ? (
          renderCardsSchedule
        ) : (
          <PSmall>Não há agendamentos marcados</PSmall>
        )}
      </InterUIContainer>

      <InterUISeparator height="8px" margin="32px 0" />

      <InterUIContainer margin="0 24px">
        <Babi />
      </InterUIContainer>
    </InterUIContainer>
  ) : (
    <InterUIContainer margin="0 24px">
      <LoadSchedule />
    </InterUIContainer>
  )
}

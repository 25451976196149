import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { LoadHome } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { SubscriptionStatusEnum } from 'src/enums/SubscriptionStatusEnum'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathQueryParams } from 'src/routes/pathQueryParams'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { NavbarActions } from 'src/store/ducks/navbar/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { SpecialtiesActions } from 'src/store/ducks/specialties/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { TabActions } from 'src/store/ducks/tab/actions'
import { ContainerFullPage } from 'src/styles/commons'

export const Start: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const isLoading = useSelector(LoadingActions.get)
  const appointmentPathParams = useSelector(PathParamsActions.getAppointmentPathParams)

  const handler = PathQueryParams()

  useEffect(() => {
    handler()
  }, [])

  useEffect(() => {
    const homeFlow = (status: string, acceptedTerms: boolean) => {
      if (status.toUpperCase() === SubscriptionStatusEnum.ATIVO) {
        acceptedTerms
          ? dispatch(
              HealthcareActions.verifyAttendanceStatus({
                history,
                pathname: TypesRoutes.HOME_DR_INTER,
              }),
            )
          : dispatch(
              SubscriptionActions.termsAndConditions({
                history,
                pathname: TypesRoutes.HOME_DR_INTER,
              }),
            )
      } else {
        dispatch(SubscriptionActions.getPricesRequest())
        dispatch(
          SpecialtiesActions.getSpecialtiesRequest({
            history,
            pathname: TypesRoutes.SUBSCRIPTION,
          }),
        )
      }
    }

    const openApplication = () => {
      dispatch(
        SubscriptionActions.getSubscriptionRequest({
          onTransmission: (status, acceptedTerms) => {
            if (
              appointmentPathParams &&
              status.toUpperCase() === SubscriptionStatusEnum.ATIVO &&
              acceptedTerms
            ) {
              if (appointmentPathParams === 'IMMEDIATE') {
                dispatch(
                  HealthcareActions.getAttendanceRequest({
                    history,
                    pathname: TypesRoutes.WAITING_ROOM,
                  }),
                )
              } else {
                dispatch(TabActions.schedule())
                dispatch(
                  SpecialtiesActions.getSpecialtiesRequest({
                    history,
                    pathname: TypesRoutes.HOME_DR_INTER,
                  }),
                )
              }
            } else {
              homeFlow(status, acceptedTerms)
            }
          },
        }),
      )

      dispatch(NavbarActions.hideHomeBtn())
    }

    if (location.search.includes('videocall')) {
      history.push(TypesRoutes.APPOINTMENT)
    } else {
      openApplication()
    }
  }, [appointmentPathParams, dispatch, history, location.search])

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_home_dr_inter',
      module: 'dr_inter',
      actions: ['home_appointment_cancellation'],
      params: {},
    })
  }, [])

  return (
    <ContainerFullPage height="0px" overflowHidden>
      {isLoading && <LoadHome />}
    </ContainerFullPage>
  )
}

import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { CardSchedule } from 'src/components/CardSchedule/CardSchedule'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { PSmall } from 'src/styles/commons'

import { InterUIAlert, InterUIContainer } from '@interco/inter-ui-react-lib'

export const UnrealizedCardDetails: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const appointment = useSelector(SchedulingActions.getSelectedAppointment)
  const { patientId } = useSelector(SubscriptionActions.getHolder)

  const scheduleNewAppointment = () => {
    const { type } = appointment.professional

    if (type === 'DOCTOR') {
      dispatch(SchedulingActions.getSpecialtiesListRequest())
    } else {
      dispatch(
        SchedulingActions.getProfessionalsListRequest({
          type,
          page: 1,
          patientId: patientId as number,
        }),
      )
    }

    BaseBridge.ticWb({
      name: 'btn_reschedule_appointment_unrealized',
      module: 'dr_inter',
      action: 'reschedule_appointment_unrealized',
      params: {
        type,
      },
    })

    dispatch(
      SchedulingActions.setProfessionalType({
        history,
        pathname: type === 'DOCTOR' ? TypesRoutes.SPECIALTIES : TypesRoutes.PROFESSIONAL,
        type,
      }),
    )
  }

  useBasePage({
    navbarTitle: PageTitles.APPOINTMENT_UNREALIZED,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const stickyFooter = <Button onClick={scheduleNewAppointment}>Agendar nova consulta</Button>

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_unrealized_card',
      module: 'dr_inter',
      actions: ['click_detail'],
      params: {},
    })
  }, [])

  return (
    <InterUIContainer margin="24px" stickyFooter={stickyFooter}>
      <CardSchedule appointmentDetails={appointment} showStatus={!appointment.status} />
      <InterUIAlert type="attention" margin="24px 0 32px 0">
        <PSmall bold scale={500} marginBottom="4px">
          Sua consulta não foi realizada
        </PSmall>
        <PSmall scale={500} marginBottom="0">
          Nesses casos, o valor cobrado será estornado em até 24 horas, direto na sua conta Inter.
          Você pode agendar uma nova consulta sempre que quiser.
        </PSmall>
      </InterUIAlert>
    </InterUIContainer>
  )
}

import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, LoadDoctorDetails } from 'src/components'
import { ExitScheduleBS } from 'src/components/ExitScheduleBS/ExitScheduleBS'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { IAppointment, IDoctorDetails, IProfessional } from 'src/store/ducks/scheduling/types'
import { ContainerFullPage, H2, H3, PMedium, PSmall, SearchContainer } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIBottomSheet,
  InterUIContainer,
  InterUIListDescription,
  InterUILoading,
  InterUIProgressBar,
  InterUISeparator,
  InterUITag,
  RemoveAccents,
} from '@interco/inter-ui-react-lib'

import { ContentLoading, Info, ListEducation } from './Professional.style'

export const Professional: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const isLoading = useSelector(LoadingActions.get)
  const selectedSpecialty = useSelector(SchedulingActions.getSelectedSpecialty)
  const doctorsList = useSelector(SchedulingActions.getDoctorsList)
  const professionalsList = useSelector(SchedulingActions.getProfessionalsList)
  const doctorDetails = useSelector(SchedulingActions.getDoctorDetails)
  const professionalType = useSelector(SchedulingActions.getProfessionalType)
  const realizedAppointments = useSelector(SchedulingActions.getRealizedAppointments)

  const iconDoctor = <Icons.Doctor color={theme.colors.primary.A500} />
  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconUser = (
    <Icons.UserAccount width={16} height={16} color={theme.colors.neutral.grayscale.A500} />
  )
  const iconBuilding = (
    <Icons.Building width={16} height={16} color={theme.colors.neutral.grayscale.A500} />
  )
  const iconSearch = <Icons.Search color={theme.colors.primary.A500} className="inter-ic-search" />
  const iconEdit = (
    <Icons.Edit
      color={theme.colors.primary.A500}
      onClick={() => history.push(TypesRoutes.SPECIALTIES)}
    />
  )
  const iconProfessional = () => {
    if (professionalType?.type === 'NUTRITIONIST') {
      return <Icons.Nutritionist color={theme.colors.primary.A500} />
    }
    if (professionalType?.type === 'PSYCHOLOGIST') {
      return <Icons.Psychologist color={theme.colors.primary.A500} />
    }
    return <Icons.UserAccount color={theme.colors.primary.A500} />
  }

  const [emptySearch, setEmptySearch] = useState(true)
  const [professionalListView, setProfessionalListView] = useState<IProfessional[]>([])
  const [professionalBS, setProfessionalBS] = useState(false)
  const [exitBS, setExitBS] = useState(false)

  const searchRef = useRef<HTMLInputElement>(null)
  let debounce: NodeJS.Timeout

  useBasePage({
    navbarTitle: PageTitles.APPOINTMENT_SCHEDULING,
    showExitBS: true,
    homeNavigationHandler: () => setExitBS(true),
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    professionalType?.type === 'DOCTOR'
      ? setProfessionalListView([...doctorsList])
      : setProfessionalListView([...professionalsList])
  }, [doctorsList, professionalsList, professionalType])

  const renderCardSpecialty = (
    <>
      <H2 margin="24px 0">Escolha o profissional para sua consulta</H2>
      <InterUIListDescription
        iconLeft={iconDoctor}
        iconRight={iconEdit}
        withBorder
        data-testid="card-specialty"
      >
        <PMedium marginBottom="0" bold>
          {selectedSpecialty.description}
        </PMedium>
      </InterUIListDescription>
      <InterUISeparator variant="small" margin="24px 0" />
    </>
  )

  const renderCardLastConsult = realizedAppointments.length !== 0 && (
    <>
      <H3>Ou consulte novamente</H3>
      <InterUIListDescription
        key="professional-test"
        id="professional-test"
        data-testid="professional-test"
        iconLeft={iconProfessional()}
        iconRight={iconArrow}
        withBorder
        margin="16px 0 0"
        onClick={() => handleRealizedAppointments(realizedAppointments[0])}
      >
        <PMedium marginBottom="4px" bold>
          {realizedAppointments[0].professional.name}
        </PMedium>
        <PSmall marginBottom="0px" scale={400}>
          {realizedAppointments[0].professional.specialty}
        </PSmall>
        <PSmall marginBottom="0px" scale={400}>
          {professionalType?.type === 'DOCTOR' &&
            `CRM ${realizedAppointments[0].professional.councilNumber}/${realizedAppointments[0].professional.councilUf}`}

          {professionalType?.type === 'NUTRITIONIST' &&
            `CRN ${realizedAppointments[0].professional.councilNumber}/${realizedAppointments[0].professional.councilUf}`}

          {professionalType?.type === 'PSYCHOLOGIST' &&
            `CRP ${realizedAppointments[0].professional.councilNumber}/${realizedAppointments[0].professional.councilUf}`}
        </PSmall>
        <InterUITag
          color={theme.colors.theme}
          backgroundColor={theme.colors.primary.A500}
          margin="4px 0 0"
        >
          Última consulta realizada
        </InterUITag>
      </InterUIListDescription>
      <InterUISeparator variant="small" margin="24px 0" />
    </>
  )

  const renderCardProfessional =
    professionalListView.length !== 0 ? (
      professionalListView.map((item, index) => (
        <InterUIListDescription
          key={`professional-${item.id}`}
          id={`professional-${index}`}
          data-testid={`professional-${index}`}
          iconLeft={iconProfessional()}
          iconRight={iconArrow}
          withBorder
          margin="0 0 16px"
          onClick={() => handleSelectedProfessional(item)}
        >
          <PMedium marginBottom="4px" bold>
            {item.name}
          </PMedium>
          <PSmall marginBottom="0px" scale={400}>
            {item.specialty}
          </PSmall>
          <PSmall marginBottom="0px" scale={400}>
            {professionalType?.type === 'DOCTOR' && `CRM ${item.councilNumber}/${item.councilUf}`}

            {professionalType?.type === 'NUTRITIONIST' &&
              `CRN ${item.councilNumber}/${item.councilUf}`}

            {professionalType?.type === 'PSYCHOLOGIST' &&
              `CRP ${item.councilNumber}/${item.councilUf}`}
          </PSmall>
        </InterUIListDescription>
      ))
    ) : (
      <PSmall>Não há agenda para este médico(a)</PSmall>
    )

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement
    const search = RemoveAccents(value, true)

    setEmptySearch(!search)
    clearTimeout(debounce)

    debounce = setTimeout(() => {
      if (search) {
        const type = professionalType?.type === 'DOCTOR' ? doctorsList : professionalsList
        const updatedListView = type.filter((item) =>
          RemoveAccents(item.name, true).includes(search),
        )
        setProfessionalListView(updatedListView)
      } else {
        professionalType?.type === 'DOCTOR'
          ? setProfessionalListView(doctorsList)
          : setProfessionalListView(professionalsList)
      }
    }, 150)
  }

  const deleteSearchValue = () => {
    setEmptySearch(true)

    professionalType?.type === 'DOCTOR'
      ? setProfessionalListView(doctorsList)
      : setProfessionalListView(professionalsList)

    if (searchRef.current) {
      searchRef.current.value = ''
    }
  }

  const search = (
    <SearchContainer>
      <input
        ref={searchRef}
        data-testid="search"
        type="search"
        name="professional-search"
        placeholder="Pesquisar"
        autoComplete="off"
        onKeyUp={handleSearch}
        aria-labelledby="professional-search-button"
      />

      {emptySearch ? (
        iconSearch
      ) : (
        <Icons.Exit
          color={theme.colors.primary.A500}
          className="inter-ic-exit"
          data-testid="deleteSearchValue"
          onClick={deleteSearchValue}
        />
      )}
    </SearchContainer>
  )

  const handleRealizedAppointments = (appointment: IAppointment) => {
    const { professional } = appointment
    const selectedProfessional: IProfessional = {
      id: professional.id,
      name: professional.name,
      specialty: professional.specialty,
      councilNumber: professional.councilNumber,
      councilUf: professional.councilUf,
    }

    handleSelectedProfessional(selectedProfessional)
  }

  const handleSelectedProfessional = (professional: IProfessional) => {
    if (professionalType?.type === 'DOCTOR') {
      setProfessionalBS(true)
      dispatch(SchedulingActions.getDoctorDetailsRequest({ doctorId: professional.id }))
    }

    if (professionalType?.type !== 'DOCTOR') handleSchedule(professional)
  }

  const handleScheduleBS = ({ id, name, specialty, councilNumber, councilUf }: IDoctorDetails) => {
    const professional = {
      id,
      name,
      specialty,
      councilNumber,
      councilUf,
    }

    handleSchedule(professional)
  }

  const handleSchedule = (professional: IProfessional) => {
    setProfessionalBS(false)

    if (professional) {
      BaseBridge.ticWb({
        name: 'btn_select_professional',
        module: 'dr_inter',
        action: 'select_professional',
        params: {
          professional,
        },
      })

      dispatch(SchedulingActions.setSelectedProfessional(professional))
      history.push(TypesRoutes.APPOINTMENT_DATE)
    }
  }

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_choose_professional',
      module: 'dr_inter',
      actions: [
        'select_specialty',
        'select_psychologist',
        'select_nutritionist',
        'reschedule_appointment_unrealized',
        'reschedule_appointment_cancelled',
        'schedule_appointment_cancellation',
      ],
      params: {},
    })
  }, [])

  return (
    <>
      <InterUIContainer margin="0">
        <InterUIProgressBar progress={professionalType?.type === 'DOCTOR' ? '50%' : '66%'} />

        <ContainerFullPage height="2px" margin="0 24px">
          <PSmall margin="24px 0 0" bold scale={300}>
            {professionalType?.type === 'DOCTOR'
              ? 'Passo 2/4 - Profissional'
              : 'Passo 1/3 - Profissional'}
          </PSmall>

          {professionalType?.type === 'DOCTOR' && renderCardSpecialty}

          {professionalType?.type === 'NUTRITIONIST' && (
            <H2 margin="24px 0">Escolha o profissional de nutrição desejado</H2>
          )}

          {professionalType?.type === 'PSYCHOLOGIST' && (
            <H2 margin="24px 0">Escolha o profissional de psicologia desejado</H2>
          )}

          {search}

          {isLoading ? (
            <ContentLoading>
              <InterUILoading size="md" />
              <PMedium margin="16px 0 0" scale={400}>
                Estamos procurando resultados para a sua pesquisa.
              </PMedium>
            </ContentLoading>
          ) : (
            <>
              {renderCardLastConsult}
              {renderCardProfessional}
            </>
          )}
        </ContainerFullPage>
      </InterUIContainer>

      <InterUIBottomSheet
        data-testid="doctor-details-BS"
        toggle={professionalBS}
        onHide={() => setProfessionalBS(false)}
      >
        {!isLoading ? (
          <>
            <H3 margin="8px 0 4px">{doctorDetails?.name}</H3>
            <PMedium
              marginBottom="24px"
              scale={400}
            >{`${doctorDetails.specialty} - CRM ${doctorDetails.councilNumber}/${doctorDetails.councilUf}`}</PMedium>

            {doctorDetails.about !== '' && (
              <>
                <Info>
                  {iconUser}
                  <PSmall marginBottom="0" bold>
                    Sobre
                  </PSmall>
                </Info>
                <PSmall margin="8px 0 24px" scale={400}>
                  {doctorDetails.about}
                </PSmall>
              </>
            )}

            {doctorDetails.qualifications?.length > 0 && (
              <>
                <InterUISeparator margin="0 0 24px" variant="small" />
                <Info>
                  {iconBuilding}
                  <PSmall marginBottom="0" bold>
                    Formação acadêmica
                  </PSmall>
                </Info>
                <ListEducation>
                  {doctorDetails.qualifications.map((item, index) => {
                    const key = index
                    return (
                      <PSmall key={key} marginBottom="0" scale={400}>
                        {item.institution}
                      </PSmall>
                    )
                  })}
                </ListEducation>
              </>
            )}

            <Button onClick={() => handleScheduleBS(doctorDetails)}>Agendar um horário</Button>
          </>
        ) : (
          <LoadDoctorDetails />
        )}
      </InterUIBottomSheet>

      <ExitScheduleBS toggleState={[exitBS, setExitBS]} />
    </>
  )
}

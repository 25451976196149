import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, PaymentMethod, WaitingPayment } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { CheckoutBaseService } from 'src/config/bridge/CheckoutBaseService'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { ErrorDetails } from 'src/store/ducks/error/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { H2, PMedium, PSmall, PriceTag } from 'src/styles/commons'
import { numberFormat } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIBottomSheet, InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'

export const CancellationFine: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const iconCredit = <Icons.Card color={theme.colors.primary.A500} />
  const iconDebit = <Icons.Wallet color={theme.colors.primary.A500} />
  const iconPayment = <Icons.Dollar color={theme.colors.neutral.grayscale.A500} />
  const iconArrow = <Icons.ChevronRight color={theme.colors.neutral.grayscale.A400} />
  const iconArrowOrange = <Icons.ChevronRight color={theme.colors.primary.A500} />

  const [inWaitPayment, setInWaitPayment] = useState(false)
  const [confirmCancellationBS, setConfirmCancellationBS] = useState(false)

  const isLoading = useSelector(LoadingActions.get)
  const holder = useSelector(SubscriptionActions.getHolder)
  const { cancellationAmount } = useSelector(SubscriptionActions.getCancellationFee)
  const formOfPayment = useSelector(CheckoutActions.getFormOfPayment)

  useBasePage({
    navbarTitle: PageTitles.CANCELLATION,
    hideBackNavigationBtn: inWaitPayment,
    hideHomeBtn: true,
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    if (formOfPayment.type) {
      setPaymentCardConfig({
        border: theme.colors.primary.A400,
        icon: formOfPayment.type === 'DEBIT' ? iconDebit : iconCredit,
        title: formOfPayment.title,
        subTitle: formOfPayment.subTitle,
        arrow: iconArrowOrange,
      })
    }
  }, [])

  const openChoicePaymentForm = async () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ASSINATURA_CANCEL_T_MULTA_PGTO.name, {
      multa_cancelamento: cancellationAmount,
    })

    try {
      const response = await CheckoutBaseService.openWallet(
        cancellationAmount,
        'CANCELLATION',
        holder.patientId?.toString(),
      )

      dispatch(CheckoutActions.setFormOfPayment(response))

      setPaymentCardConfig({
        border: theme.colors.primary.A400,
        icon: response.type === 'DEBIT' ? iconDebit : iconCredit,
        title: response.title,
        subTitle: response.subTitle,
        arrow: iconArrowOrange,
      })
    } catch (error: unknown) {
      const errorDetails = {
        title: 'Houve um erro por aqui',
        subTitle:
          'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
        disabledButton: false,
        route: TypesRoutes.HOME_DR_INTER,
      }

      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'CancellationFine.openWallet',
      })

      dispatch(ErrorActions.show(errorDetails))
    }
  }

  const [paymentCardConfig, setPaymentCardConfig] = useState({
    border: theme.colors.neutral.grayscale.A200,
    icon: iconPayment,
    title: 'Formas de pagamento',
    subTitle: '',
    arrow: iconArrow,
  })

  const processTransactionStatus = (status: string) => {
    const errorDetails = {
      title: 'Transação negada',
      subTitle:
        'Houve um erro na transação requisitada, por favor, entre em contato com o suporte.',
      route: TypesRoutes.HOME_DR_INTER,
    }
    switch (status) {
      case 'PROCESSED':
        BaseBridge.ticWb({
          name: 'case_status_processed',
          module: 'dr_inter',
          action: 'status_processed',
          params: {
            status,
          },
        })
        dispatch(
          SubscriptionActions.cancelSubscription({
            history,
            pathname: TypesRoutes.CANCELLATION_FINE_SUCCESS,
          }),
        )
        break
      case 'DENIED':
        dispatch(ErrorActions.show(errorDetails))
        break
      default:
        break
    }
  }

  const finalizeCancellation = async () => {
    if (BaseBridge.isBrowser()) {
      processTransactionStatus('PROCESSED')
    } else {
      try {
        const result = await CheckoutBaseService.contractWallet(
          numberFormat(Number(cancellationAmount)).replace(`,`, `.`),
        )

        const payload = {
          transactionId: result.transactionId,
          onTransmission: processTransactionStatus,
        }

        dispatch(CheckoutActions.deleteTransactionId())
        dispatch(CheckoutActions.setTransactionId(result.transactionId))
        dispatch(SubscriptionActions.getTransactionStatus(payload))

        setInWaitPayment(true)
        setConfirmCancellationBS(false)
      } catch (error: unknown) {
        const errorDetails: ErrorDetails = {
          title: 'Houve um erro por aqui',
          subTitle:
            'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
          disabledButton: false,
          route: TypesRoutes.START,
        }

        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'Cancellation.finalizeContract',
        })

        dispatch(ErrorActions.show(errorDetails))
      }
    }
  }

  const stickyFooter = (
    <Button
      data-testid="button-confirm-cancellation"
      margin="24px"
      onClick={() => setConfirmCancellationBS(true)}
      disabled={paymentCardConfig.title === `Formas de pagamento` || isLoading}
    >
      Confirmar cancelamento
    </Button>
  )

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_cancellation_fine',
      module: 'dr_inter',
      actions: ['confirm_cancellation_conditions_fine'],
      params: {},
    })
  }, [])

  return inWaitPayment ? (
    <WaitingPayment />
  ) : (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIContainer margin="0 24px 32px">
          <PriceTag marginBottom="10px">
            <PSmall scale={400}>Taxa de cancelamento</PSmall>
            <H2>{numberFormat(Number(cancellationAmount))}</H2>
          </PriceTag>
          <PSmall marginBottom="24px" scale={400} textAlign="center">
            Para clientes que realizaram consultas e desejam cancelar o plano antes de 12 meses.
          </PSmall>
          <InterUISeparator margin="24px 0 24px" variant="small" />
          <H2 marginBottom="8px">Forma de pagamento</H2>
          <PMedium marginBottom="24px" scale={400}>
            Se quiser, você pode escolher outra forma de pagamento para continuar o cancelamento.
          </PMedium>

          <PaymentMethod
            title={paymentCardConfig.title}
            subTitle={paymentCardConfig.subTitle}
            border={paymentCardConfig.border}
            iconLeft={paymentCardConfig.icon}
            iconRight={paymentCardConfig.arrow}
            openChoicePaymentForm={openChoicePaymentForm}
          />
        </InterUIContainer>
      </InterUIContainer>

      <InterUIBottomSheet
        data-testid="confirm-cancellation-bottom-sheet"
        toggle={confirmCancellationBS}
        onHide={() => setConfirmCancellationBS(false)}
        title="Confirmar transação"
      >
        <PMedium scale={400} marginBottom="32px">
          Confirme a transação para autenticá-la com o i-safe.
        </PMedium>
        <Button variant="primary" onClick={() => finalizeCancellation()}>
          Finalizar cancelamento
        </Button>
      </InterUIBottomSheet>
    </>
  )
}

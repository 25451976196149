import React, { useEffect } from 'react'

import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { ContainerFullPage, H1, PMedium, SuccessSign } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { Cancellation } from './CancellationSuccess.styles'

export const CancellationSuccess: React.FC = () => {
  const theme = useTheme()

  const successIcon = <Icons.CheckCircle width={32} height={32} color={theme.colors.base.white} />

  useBasePage({
    navbarTitle: PageTitles.DR_INTER,
    hideHomeBtn: false,
    hideBackNavigationBtn: true,
  })

  const backHomeInsurance = () => {
    BaseBridge.openDeepLink('bancointer://seguros')
  }

  const stickyFooter = (
    <Button margin="24px" onClick={backHomeInsurance}>
      Voltar para home de seguros
    </Button>
  )

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_cancellation_success',
      module: 'dr_inter',
      actions: ['confirm_cancellation_conditions_isafe'],
      params: {},
    })
  }, [])

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <ContainerFullPage margin="0 24px">
          <Cancellation>
            <SuccessSign>{successIcon}</SuccessSign>
            <H1 margin="40px 0 8px" textAlign="center">
              Você cancelou sua assinatura
            </H1>
            <PMedium margin="8px 0 0" scale={400} textAlign="center">
              Mesmo com o cancelamento, você poderá contratar novamente o Doutor Inter quando
              quiser!
            </PMedium>
          </Cancellation>
        </ContainerFullPage>
      </InterUIContainer>
    </>
  )
}

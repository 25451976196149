import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { MedicinesActions } from 'src/store/ducks/medicines/actions'
import { IMedicineFields } from 'src/store/ducks/medicines/types'
import { ContainerFullPage, H2, PMedium, SearchContainer } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIListDescription,
  InterUILoading,
  InterUIProgressBar,
  RemoveAccents,
} from '@interco/inter-ui-react-lib'

import { ContentLoading, ContentMedicine, TextMedicine } from './Medicines.styles'

export const Medicines: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const searchRef = useRef<HTMLInputElement>(null)

  const isLoading = useSelector(LoadingActions.get)
  const medicinesList = useSelector(MedicinesActions.getMedicinesList)
  const selectedMedicines = useSelector(MedicinesActions.getSelectedMedicines)

  const [emptySearch, setEmptySearch] = useState(true)

  const iconSearch = <Icons.Search color={theme.colors.primary.A500} className="inter-ic-search" />
  const iconMedicine = <Icons.Pill color={theme.colors.primary.A500} />
  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />

  let debounce: NodeJS.Timeout

  useEffect(() => {
    if (emptySearch) {
      dispatch(MedicinesActions.setMedicinesList([]))
    }
  }, [dispatch, emptySearch])

  useBasePage({
    navbarTitle: PageTitles.CONSULT_NOW,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  const deleteSearchValue = () => {
    setEmptySearch(true)
    dispatch(MedicinesActions.setMedicinesList([]))

    if (searchRef.current) {
      searchRef.current.value = ''
    }
  }

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement

    const search = RemoveAccents(value).toLowerCase()

    setEmptySearch(!search)

    if (medicinesList.length > 0) {
      dispatch(MedicinesActions.setMedicinesList([]))
    }

    clearTimeout(debounce)

    const payload = {
      medicine: search,
    }

    debounce = setTimeout(() => {
      if (search.length >= 3) {
        dispatch(MedicinesActions.getMedicinesListRequest(payload))
      } else {
        dispatch(MedicinesActions.setMedicinesList([]))
        dispatch(LoadingActions.hide())
      }
    }, 1000)
  }

  const handleMedicineSelected = (medicine: IMedicineFields) => {
    const findRepeatMedicine = selectedMedicines.find((selected) => selected.id === medicine.id)

    !findRepeatMedicine
      ? (deleteSearchValue(),
        dispatch(MedicinesActions.setSelectedMedicines([...selectedMedicines, medicine])))
      : null
  }

  const handleRemoveMedicine = (medicineId: number) => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_MEDICAMENTO_T_REMOVERMED.name)
    const updateMedicine = selectedMedicines.filter((filter) => filter.id !== medicineId)

    dispatch(MedicinesActions.setSelectedMedicines(updateMedicine))
  }

  const medicinesSearch = (
    <>
      <SearchContainer>
        <input
          ref={searchRef}
          data-testid="search"
          type="search"
          name="medicines-search"
          placeholder="Pesquisar"
          autoComplete="off"
          onKeyUp={handleSearch}
          aria-labelledby="medicines-search-button"
        />

        {emptySearch ? (
          iconSearch
        ) : (
          <Icons.Exit
            color={theme.colors.primary.A500}
            className="inter-ic-exit"
            data-testid="delete-search-value"
            onClick={deleteSearchValue}
          />
        )}
      </SearchContainer>
    </>
  )

  const renderMedicinesList = medicinesList.map((item) => (
    <InterUIListDescription
      key={item.id}
      id={item.id.toString()}
      iconRight={iconArrow}
      margin="0 0 16px"
      borderBottom
      alignAllItems="center"
      onClick={() => handleMedicineSelected(item)}
    >
      <PMedium marginBottom="0" scale={400}>
        {item.name}
      </PMedium>
    </InterUIListDescription>
  ))

  const renderSelectedMedicines = selectedMedicines.map((item) => (
    <InterUIListDescription
      key={item.id}
      id={item.id.toString()}
      iconLeft={iconMedicine}
      withBorder
      margin="0 0 8px"
      role="group"
    >
      <ContentMedicine>
        <TextMedicine>
          <PMedium marginBottom="0px">{item.name}</PMedium>
        </TextMedicine>
        <Icons.Canceled
          color={theme.colors.primary.A500}
          data-testid="remove-medicine"
          onClick={() => handleRemoveMedicine(item.id)}
        />
      </ContentMedicine>
    </InterUIListDescription>
  ))

  const handleClickStickyFooter = () => {
    const content_name = selectedMedicines.map((medicine) => medicine.name).toString()

    if (selectedMedicines.length > 0) {
      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_MEDICAMENTO_T_CONTINUAR.name, {
        content_name,
      })
      BaseBridge.ticWb({
        name: 'btn_without_medicines',
        module: 'dr_inter',
        action: 'open_files_without_medicines',
        params: {
          content_name,
        },
      })
    } else {
      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_MEDICAMENTO_T_NAOINFOMAR.name)
      BaseBridge.ticWb({
        name: 'btn_with_medicines',
        module: 'dr_inter',
        action: 'open_files_with_medicines',
        params: {},
      })
    }
    history.push(TypesRoutes.ATTACH_FILES)
  }

  const stickyFooter = (
    <Button
      data-testid="button-continue"
      margin="24px"
      onClick={handleClickStickyFooter}
      disabled={isLoading}
    >
      {selectedMedicines.length > 0 ? 'Continuar' : 'Não quero informar medicamento'}
    </Button>
  )

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_medicines',
      module: 'dr_inter',
      actions: ['open_consult_medicines_page', 'open_medicines_with_symptoms'],
      params: {},
    })
  }, [])

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress="50%" />
        <ContainerFullPage height="98px" margin="0 24px">
          <H2 margin="24px 0px">Você toma algum medicamento?</H2>

          {medicinesSearch}

          {isLoading && (
            <ContentLoading>
              <InterUILoading size="md" />
              <PMedium margin="16px 0 0" scale={400}>
                Estamos procurando resultados para a sua pesquisa.
              </PMedium>
            </ContentLoading>
          )}

          {medicinesList && medicinesList.length > 0 && <>{renderMedicinesList}</>}

          {renderSelectedMedicines}
        </ContainerFullPage>
      </InterUIContainer>
    </>
  )
}

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, HoursAvailable } from 'src/components'
import { ExitScheduleBS } from 'src/components/ExitScheduleBS/ExitScheduleBS'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { IDataAndTimeAvailableRequest, ISchedules } from 'src/store/ducks/scheduling/types'
import { H2, PMedium, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUICalendar,
  InterUIContainer,
  InterUIListDescription,
  InterUILoading,
  InterUIProgressBar,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

export const AppointmentDate: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const isLoading = useSelector(LoadingActions.get)
  const professional = useSelector(SchedulingActions.getSelectedProfessional)
  const professionalType = useSelector(SchedulingActions.getProfessionalType)
  const dateAndTimeAvailable = useSelector(SchedulingActions.getDateAndTimeAvailable)

  const today = new Date()
  const currentMonth = today.getMonth() + 1

  const iconDoctor = <Icons.Doctor color={theme.colors.primary.A500} />
  const iconEditSpecialty = (
    <Icons.Edit
      color={theme.colors.primary.A500}
      data-testid="test-iconEditSpecialty"
      onClick={() => history.push(TypesRoutes.SPECIALTIES)}
    />
  )
  const iconEditProfessional = (
    <Icons.Edit
      color={theme.colors.primary.A500}
      data-testid="test-iconEditProfessional"
      onClick={() => history.push(TypesRoutes.PROFESSIONAL)}
    />
  )
  const iconProfessional = () => {
    if (professionalType?.type === 'NUTRITIONIST') {
      return <Icons.Nutritionist color={theme.colors.primary.A500} />
    }
    if (professionalType?.type === 'PSYCHOLOGIST') {
      return <Icons.Psychologist color={theme.colors.primary.A500} />
    }
    return <Icons.UserAccount color={theme.colors.primary.A500} />
  }

  const [availableDates, setAvailableDates] = useState<string[]>([])
  const [availableDatesAndTime, setAvailableDatesAndTimes] = useState<ISchedules[]>([])
  const [hoursAvailable, setHoursAvailable] = useState<string[]>([])
  const [selectedHour, setSelectedHour] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedMonth, setSelectedMonth] = useState(currentMonth)

  const [exitBS, setExitBS] = useState(false)

  useBasePage({
    navbarTitle: PageTitles.APPOINTMENT_SCHEDULING,
    showExitBS: true,
    homeNavigationHandler: () => setExitBS(true),
    backNavigationHandler: history.goBack,
  })

  /**
  Efeito responsável por setar os dias e horários disponíveis do calendário.
  */
  useEffect(() => {
    const dateAvailable: string[] = []

    if (dateAndTimeAvailable.length > 0) {
      dateAndTimeAvailable.map((obj) => dateAvailable.push(obj.date))

      setAvailableDates(dateAvailable)
      setAvailableDatesAndTimes(dateAndTimeAvailable)
    }
  }, [dateAndTimeAvailable])

  /**
  Efeito responsável por exibir os horários disponíveis do calendário.
  Primeiro IF: exibir sempre os horários do dia de hoje.
  Segundo IF: exibir os horários da data selecionada.
  */
  useEffect(() => {
    const date = new Date()
    setSelectedHour('')
    setHoursAvailable([])

    const todayDay = `${`0${date.getDate()}`.slice(-2)}`
    const formattedDayMonth = `${`0${date.getMonth() + 1}`.slice(-2)}`

    if (selectedDate === '' && date.getMonth() + 1 === selectedMonth) {
      availableDatesAndTime
        .filter((obj) => obj.date === `${todayDay}/${formattedDayMonth}/${date.getFullYear()}`)
        .map((data) => setHoursAvailable(data.times))
    } else {
      availableDatesAndTime
        .filter((obj) => obj.date === selectedDate)
        .map((data) => setHoursAvailable(data.times))
    }
  }, [availableDatesAndTime, selectedDate, selectedMonth])

  /**
  Função responsável por controlar os dias disponíveis de
  acordo com a navegação dos meses.
  @param date O mês de referencia.
  */
  const handleNavigation = (date: Date) => {
    setHoursAvailable([])
    setSelectedMonth(date.getMonth() + 1)
    setSelectedDate('')

    const formattedDayMonth = `${`0${date.getMonth() + 1}`.slice(-2)}`
    const lastDay = `${new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()}`
    const todayDay = `${`0${new Date().getDate()}`.slice(-2)}`

    if (date.getMonth() + 1 === currentMonth) {
      const request: IDataAndTimeAvailableRequest = {
        professionalId: professional.id,
        startDate: `${todayDay}/${formattedDayMonth}/${date.getFullYear()}`,
        endDate: `${lastDay}/${formattedDayMonth}/${date.getFullYear()}`,
        type: professionalType.type,
      }
      dispatch(SchedulingActions.getDateAndTimeAvailableRequest(request))
    } else {
      const request: IDataAndTimeAvailableRequest = {
        professionalId: professional.id,
        startDate: `01/${formattedDayMonth}/${date.getFullYear()}`,
        endDate: `${lastDay}/${formattedDayMonth}/${date.getFullYear()}`,
        type: professionalType.type,
      }
      dispatch(SchedulingActions.getDateAndTimeAvailableRequest(request))
    }
  }

  const renderCardSpecialty = (
    <>
      <InterUIListDescription
        margin="0 0 24px"
        iconLeft={iconDoctor}
        iconRight={iconEditSpecialty}
        withBorder
      >
        <PMedium marginBottom="0" bold>
          {professional.specialty}
        </PMedium>
      </InterUIListDescription>
    </>
  )

  /**
  Função responsável por redirecionar a tela de pagamento.
  */
  const onContinue = () => {
    dispatch(SchedulingActions.setSelectedDateTime(`${selectedDate} ${selectedHour}`))
    BaseBridge.ticWb({
      name: 'btn_select_date_time',
      module: 'dr_inter',
      action: 'select_date_time',
      params: {
        selectedDate,
        selectedHour,
      },
    })

    history.push(TypesRoutes.PAYMENT)
  }

  /**
  Função responsável por renderizar botão do footer.
  */
  const stickyFooter = (
    <Button
      data-testid="button-continue"
      margin="24px"
      disabled={selectedDate === '' || selectedHour === ''}
      onClick={onContinue}
    >
      Continuar
    </Button>
  )

  const loading = (
    <div style={{ height: '212px', display: 'flex', alignItems: 'center' }}>
      <InterUILoading data-testid="test-loading" size="ld" />
    </div>
  )

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen-SELECT_DATE_TIME',
      module: 'dr_inter',
      actions: ['select_professional'],
      params: {},
    })
  }, [])

  return (
    <>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        <InterUIProgressBar progress={professionalType?.type === 'DOCTOR' ? '75%' : '66%'} />
        <InterUIContainer margin="24px 24px 0">
          <PSmall marginBottom="0px" bold scale={300}>
            {professionalType?.type === 'DOCTOR'
              ? 'Passo 3/4 - Data e horário'
              : 'Passo 2/3 - Data e horário'}
          </PSmall>
          <H2 margin="24px 0">Escolha a data e o horário para sua consulta</H2>

          {professionalType?.type === 'DOCTOR' && renderCardSpecialty}

          <InterUIListDescription
            iconLeft={iconProfessional()}
            iconRight={iconEditProfessional}
            withBorder
          >
            <PMedium marginBottom="4px" bold>
              {professional.name}
            </PMedium>
            <PSmall marginBottom="0px" scale={400}>
              {professional.specialty}
            </PSmall>
            <PSmall marginBottom="0px" scale={400}>
              {professionalType?.type === 'DOCTOR' &&
                `CRM ${professional.councilNumber}/${professional.councilUf}`}
              {professionalType?.type === 'NUTRITIONIST' &&
                `CRN ${professional.councilNumber}/${professional.councilUf}`}
              {professionalType?.type === 'PSYCHOLOGIST' &&
                `CRP ${professional.councilNumber}/${professional.councilUf}`}
            </PSmall>
          </InterUIListDescription>
        </InterUIContainer>

        <InterUISeparator height="8px" margin="32px" />

        <InterUIContainer margin="0 24px">
          <InterUICalendar
            availableDates={availableDates}
            prevRange={0}
            nextRange={2}
            scheduledDate={(date) => setSelectedDate(date)}
            handleNavigationClick={(date) => handleNavigation(date)}
            isLoading={isLoading}
            loading={loading}
          />
          {!isLoading && (
            <>
              <PSmall margin="24px 0 8px">Horários disponíveis (Horário de Brasília)</PSmall>
              <InterUISeparator
                height="1px"
                margin="0 0 16px"
                style={{ backgroundColor: theme.colors.neutral.grayscale.A200 }}
              />
              {hoursAvailable.length > 0 ? (
                <HoursAvailable
                  selectedHour={selectedHour}
                  hoursAvailable={hoursAvailable}
                  onSelectedHour={(hour) => setSelectedHour(hour)}
                />
              ) : (
                <PSmall>Selecione uma data disponível</PSmall>
              )}
            </>
          )}
        </InterUIContainer>
      </InterUIContainer>
      <ExitScheduleBS toggleState={[exitBS, setExitBS]} />
    </>
  )
}

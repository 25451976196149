import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, PaymentMethod, WaitingPayment } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { CheckoutBaseService } from 'src/config/bridge/CheckoutBaseService'
import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'
import PDFUrlDictionary from 'src/enums/PdfUrlDictionary'
import { SubscriptionStatusEnum } from 'src/enums/SubscriptionStatusEnum'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { IFormOfPayment } from 'src/store/ducks/checkout/types'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { ErrorDetails } from 'src/store/ducks/error/types'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { BtnLink, H2, PMedium, PSmall, PriceTag } from 'src/styles/commons'
import { numberFormat } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIAlert,
  InterUIBottomSheet,
  InterUICheckBox,
  InterUIContainer,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { ContractTerms, ListBenefits } from './SubscriptionDetails.styles'

export const SubscriptionDetails: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const prices = useSelector(SubscriptionActions.getPrices)
  const isLoading = useSelector(LoadingActions.get)
  const formOfPayment = useSelector(CheckoutActions.getFormOfPayment)

  const iconPayment = <Icons.Dollar color={theme.colors.neutral.grayscale.A500} />
  const iconManual = <Icons.BookOpen color={theme.colors.neutral.grayscale.A500} />
  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconArrowOrange = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconDebit = <Icons.Wallet color={theme.colors.primary.A500} />
  const iconCredit = <Icons.Card color={theme.colors.primary.A500} />

  const [totalValue, setTotalValue] = useState(0)
  const [detailsBS, setDetailsBS] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [inWaitPayment, setInWaitPayment] = useState(false)

  const [paymentCardConfig, setPaymentCardConfig] = useState({
    border: theme.colors.neutral.grayscale.A200,
    icon: iconPayment,
    title: 'Formas de pagamento',
    subTitle: '',
    arrow: iconArrow,
  })

  const errorDetails: ErrorDetails = {
    title: 'Houve um erro por aqui',
    subTitle:
      'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
    disabledButton: false,
  }

  useBasePage({
    navbarTitle: PageTitles.DR_INTER,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  useEffect(() => {
    setTotalValue(Number(prices.price))
  }, [prices])

  useEffect(() => {
    if (formOfPayment.type) {
      setPaymentCardConfig({
        border: theme.colors.primary.A400,
        icon: formOfPayment.type === 'DEBIT' ? iconDebit : iconCredit,
        title: formOfPayment.title,
        subTitle: formOfPayment.subTitle,
        arrow: iconArrowOrange,
      })
    }
  }, [])

  const openChoicePaymentForm = async () => {
    try {
      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CONTRATACAO_T_PAGAMENTO.name)
      const response = await CheckoutBaseService.openWallet(prices.price, 'SUBSCRIPTION')

      dispatch(CheckoutActions.setFormOfPayment(response))

      setPaymentCardConfig({
        border: theme.colors.primary.A400,
        icon: response.type === 'DEBIT' ? iconDebit : iconCredit,
        title: response.title,
        subTitle: response.subTitle,
        arrow: iconArrowOrange,
      })
    } catch (error: unknown) {
      errorDetails.route = TypesRoutes.START

      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'Subscription.openWallet',
      })

      dispatch(ErrorActions.show(errorDetails))
    }
  }

  const processSubscriptionStatus = (status: string) => {
    errorDetails.route = TypesRoutes.START

    if (status === SubscriptionStatusEnum.ATIVO) {
      dispatch(
        SubscriptionActions.termsAndConditions({
          history,
          pathname: TypesRoutes.SUBSCRIPTION_SUMMARY,
        }),
      )
    } else if (
      status === SubscriptionStatusEnum.ERRO_PAGAMENTO ||
      status === SubscriptionStatusEnum.ERRO_ATIVACAO
    ) {
      dispatch(ErrorActions.show(errorDetails))
    }
  }

  const finalizeContract = async () => {
    try {
      BaseBridge.ticWb({
        name: 'btn_hire_subscription',
        module: 'dr_inter',
        action: 'open_summary_page',
        params: {
          forma_pag: paymentCardConfig.title,
          valor: totalValue.toString(),
        },
      })
      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CONTRATACAO_T_CONTRATAR.name, {
        forma_pag: paymentCardConfig.title,
        valor: totalValue.toString(),
      })

      await CheckoutBaseService.contractWallet(prices.price)

      const payload = {
        onTransmission: processSubscriptionStatus,
      }

      dispatch(SubscriptionActions.getSubscriptionStatus(payload))
      dispatch(CheckoutActions.setFormOfPayment({} as IFormOfPayment))
      setInWaitPayment(true)
    } catch (error: unknown) {
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'Subscription.finalizeContract',
      })

      dispatch(ErrorActions.show(errorDetails))
    }
  }

  const goToProductManual = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CONTRATACAO_T_MANUAL.name)
    BaseBridge.openPdf(PDFUrlDictionary.MANUAL, 'Manual do Doutor Inter')
  }

  const openContractTerms = async () => {
    BaseBridge.openPdf(PDFUrlDictionary.TERMS_CONDITIONS, 'Contrato do Doutor Inter')
  }

  const openPrivacyPolicy = async () => {
    BaseBridge.openPdf(PDFUrlDictionary.PRIVACY_POLICY, 'Política de Privacidade')
  }

  const onDetailsBs = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CONTRATACAO_T_DETALHES.name)
    setDetailsBS(true)
  }

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_details_subscription',
      module: 'dr_inter',
      actions: ['open_subscription_details_page'],
      params: {},
    })
  }, [])

  const stickyFooter = (
    <>
      <InterUISeparator height="1px" />

      <InterUIContainer margin="0 24px 16px">
        <InterUICheckBox
          value=" "
          name="no-number"
          id="no-number"
          onChange={() => setAcceptTerms(!acceptTerms)}
          checked={acceptTerms}
          data-testid="accept-terms"
        >
          <ContractTerms>
            <PSmall margin="2px 0">
              Li e concordo com as informações descritas do documento nos{' '}
              <BtnLink onClick={openContractTerms}>Termos e Condições</BtnLink> e na{' '}
              <BtnLink onClick={openPrivacyPolicy}>Politica de Privacidade da Conexa.</BtnLink>
            </PSmall>
          </ContractTerms>
        </InterUICheckBox>

        <Button
          disabled={paymentCardConfig.title === `Formas de pagamento` || !acceptTerms || isLoading}
          onClick={finalizeContract}
        >
          Contratar
        </Button>
      </InterUIContainer>
    </>
  )

  const recurringPayment = (
    <InterUIAlert margin="24px 0 32px 0">
      <PSmall bold scale={500} marginBottom="4px">
        As próximas cobranças serão feitas nesse dia do mês
      </PSmall>
      <PSmall scale={500} marginBottom="0">
        A data da contratação do plano é a mesma em que o pagamento da mensalidade acontece.
      </PSmall>
    </InterUIAlert>
  )

  return inWaitPayment ? (
    <WaitingPayment />
  ) : (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIContainer margin="24px 24px 32px">
        <H2 marginBottom="16px">Seu plano de assinatura mensal de telemedicina</H2>
        <PriceTag marginBottom="16px">
          <PSmall marginBottom="4px" scale={400}>
            Por apenas
          </PSmall>
          <H2>{numberFormat(totalValue)} por mês</H2>
        </PriceTag>

        <BtnLink onClick={onDetailsBs}>Mostrar os detalhes</BtnLink>
      </InterUIContainer>

      <InterUISeparator />

      <InterUIContainer margin="32px 24px">
        <H2 marginBottom="16px">Como você quer pagar?</H2>

        <PaymentMethod
          title={paymentCardConfig.title}
          subTitle={paymentCardConfig.subTitle}
          border={paymentCardConfig.border}
          iconLeft={paymentCardConfig.icon}
          iconRight={paymentCardConfig.arrow}
          openChoicePaymentForm={openChoicePaymentForm}
        />

        {formOfPayment.type && recurringPayment}
      </InterUIContainer>

      <InterUISeparator />

      <InterUIContainer margin="32px 24px 16px">
        <InterUIListDescription
          iconLeft={iconManual}
          iconRight={iconArrow}
          alignAllItems="center"
          onClick={goToProductManual}
        >
          <PMedium marginBottom="0" bold>
            Manual do produto
          </PMedium>
          <PMedium marginBottom="0" scale={400}>
            Manual do Doutor Inter
          </PMedium>
        </InterUIListDescription>

        <InterUISeparator variant="small" margin="24px 0" />

        <PMedium marginBottom="0" scale={400}>
          Doutor Inter é um serviço de telemedicina <strong>exclusivo ao titular do plano</strong> ,
          prestado pela Conexa Saúde e ofertado pelo Inter.
        </PMedium>
      </InterUIContainer>

      <InterUIBottomSheet
        data-testid="details-bottom-sheet"
        toggle={detailsBS}
        onHide={() => setDetailsBS(false)}
        title="Detalhes do Doutor Inter"
      >
        <ListBenefits>
          <li>
            <PMedium bold>Descontos em farmácias</PMedium>
            <PMedium scale={400} marginBottom="0">
              Você conta com uma grande rede de farmácias credenciadas e com ofertas exclusivas.
            </PMedium>
          </li>
          <li>
            <PMedium bold>Descontos em exames laboratoriais</PMedium>
            <PMedium scale={400} marginBottom="0">
              Nossa rede credenciada inclui também os principais laboratórios da sua região, onde
              você tem acesso a preços especiais.
            </PMedium>
          </li>
          <li>
            <PMedium bold>Você livre de papelada</PMedium>
            <PMedium scale={400} marginBottom="0">
              Os documentos são 100% digitais: você não precisa guardar documentos, receitas ou
              encaminhamentos.
            </PMedium>
          </li>
        </ListBenefits>

        <Button variant="primary" margin="24px 0 0" onClick={() => setDetailsBS(false)}>
          Entendi
        </Button>
      </InterUIBottomSheet>
    </InterUIContainer>
  )
}

import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Babi, LogoConexa } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import PDFUrlDictionary from 'src/enums/PdfUrlDictionary'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { TabActions } from 'src/store/ducks/tab/actions'
import { PMedium, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { maskCpf, maskPhone } from 'src/utils/masks'
import { useTheme } from 'styled-components'

import {
  InterUIBox,
  InterUIContainer,
  InterUIList,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

export const Profile: React.FC = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()

  const holder = useSelector(SubscriptionActions.getHolder)

  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />

  const mySubscriptionDetails = () => {
    BaseBridge.ticWb({
      name: 'btn_subscription_details',
      module: 'dr_inter',
      action: 'subscription_details',
      params: {
        id: holder.patientId,
      },
    })
    history.push(TypesRoutes.MY_SUBSCRIPTION_DETAILS)
  }

  useEffect(() => {
    dispatch(TabActions.profile())
  }, [dispatch])

  const openManual = () => {
    BaseBridge.openPdf(PDFUrlDictionary.MANUAL, 'Manual do Doutor Inter')
  }

  return (
    <>
      <InterUIContainer margin="0">
        <InterUIContainer margin="0 24px">
          <InterUIBox direction="column" align="stretch" margin="0 0 24px">
            <InterUIList
              margin="0 0 16px"
              value={
                <PSmall bold marginBottom="0px">
                  {holder.name}
                </PSmall>
              }
              text={
                <PSmall scale={400} marginBottom="0px">
                  Nome completo
                </PSmall>
              }
            />
            <InterUIList
              margin="0 0 16px"
              value={
                <PSmall bold marginBottom="0px">
                  {holder.birthdate}
                </PSmall>
              }
              text={
                <PSmall scale={400} marginBottom="0px">
                  Data de nascimento
                </PSmall>
              }
            />
            <InterUIList
              margin="0 0 16px"
              value={
                <PSmall bold marginBottom="0px">
                  {maskCpf(holder.socialId)}
                </PSmall>
              }
              text={
                <PSmall scale={400} marginBottom="0px">
                  CPF
                </PSmall>
              }
            />
            <InterUIList
              margin="0 0 16px"
              value={
                <PSmall bold marginBottom="0px">
                  {holder.email}
                </PSmall>
              }
              text={
                <PSmall scale={400} marginBottom="0px">
                  E-mail
                </PSmall>
              }
            />
            <InterUIList
              value={
                <PSmall bold marginBottom="0px">
                  {maskPhone(holder.phone)}
                </PSmall>
              }
              text={
                <PSmall scale={400} marginBottom="0px">
                  Celular
                </PSmall>
              }
            />
          </InterUIBox>

          <InterUISeparator variant="small" />

          <InterUIListDescription
            data-testid="test-my-subscription"
            iconRight={iconArrow}
            withBorder
            margin="24px 0px 16px"
            onClick={mySubscriptionDetails}
            role="group"
          >
            <PMedium marginBottom="0px" bold>
              Minha assinatura
            </PMedium>
          </InterUIListDescription>

          <InterUIListDescription
            data-testid="test-manual-drInter"
            iconRight={iconArrow}
            withBorder
            margin="0px 0px 16px"
            onClick={openManual}
            role="group"
          >
            <PMedium marginBottom="0px" bold>
              Manual do Doutor Inter
            </PMedium>
          </InterUIListDescription>

          <LogoConexa />
        </InterUIContainer>

        <InterUISeparator height="8px" margin="32px 0" />

        <InterUIContainer margin="0 24px">
          <Babi />
        </InterUIContainer>
      </InterUIContainer>
    </>
  )
}

import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, CaptureImage } from 'src/components'
import { AttachmentBS } from 'src/components/AttachmentBS/AttachmentBS'
import { CameraScreens } from 'src/components/CaptureImage/CaptureImage'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { FilesActions } from 'src/store/ducks/attachFiles/actions'
import { IAttachedFile } from 'src/store/ducks/attachFiles/types'
import { H1, PLarge, PMedium, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIAttachmentCard,
  InterUIBottomSheet,
  InterUIButton,
  InterUIContainer,
  InterUIProgressBar,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

export const AttachFiles: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const selectedFilesAttached = useSelector(FilesActions.getSelectedFilesAttached)

  const [attachedFiles, setAttachedFiles] = useState<IAttachedFile[]>([])
  const [bottomSheetError, setBottomSheetError] = useState<boolean>(false)
  const [bottomSheetErrorMsg, setBottomSheetErrorMsg] = useState<Error>()
  const [bottomSheetAttachmentOptions, setBottomSheetAttachmentOptions] = useState(false)

  const [showCamera, setShowCamera] = useState(false)
  const [cameraScreen, setCameraScreen] = useState(CameraScreens.PHOTO)

  const theme = useTheme()

  const iconChevronRight = (
    <Icons.Attachment width={24} height={24} color={theme.colors.primary.A500} />
  )

  interface titleOptions {
    [key: string]: PageTitles
  }

  const chooseTitle: titleOptions = {
    INSTRUCTIONS: PageTitles.EMPTY,
    PHOTO: PageTitles.TAKE_THE_PICTURE,
    REVIEW: PageTitles.REVIEW_THE_PICTURE,
  }

  const setNavBarTitle = () => (!showCamera ? PageTitles.CONSULT_NOW : chooseTitle[cameraScreen])

  const backNavigationHandler = () => {
    if (!showCamera) {
      history.goBack()
    }
    setShowCamera(false)
  }

  useBasePage({
    navbarTitle: setNavBarTitle(),
    backNavigationHandler,
    hideBackNavigationBtn: cameraScreen === CameraScreens.INSTRUCTIONS,
    hideHomeBtn: true,
  })

  useEffect(() => {
    const files = Object.assign([], selectedFilesAttached.files)

    setAttachedFiles(files)
  }, [selectedFilesAttached.files])

  const onContinue = () => {
    if (attachedFiles.length === 0) {
      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ANEXO_T_NAOINFOMAR.name)
      BaseBridge.ticWb({
        name: 'btn_without_files',
        module: 'dr_inter',
        action: 'open_payment_methods_without_files',
        params: {},
      })
    } else {
      BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_ANEXO_T_CONTINUAR.name)
      BaseBridge.ticWb({
        name: 'btn_with_files',
        module: 'dr_inter',
        action: 'open_payment_methods_with_files',
        params: {},
      })
    }

    history.push(TypesRoutes.PAYMENT_CONSULT)
  }

  const onRemoveFile = (id: number) => {
    const tempFiles = attachedFiles.filter((item) => item.id !== id)

    setAttachedFiles(tempFiles)
    dispatch(FilesActions.setSelectedFilesAttached({ files: tempFiles }))
  }

  const attachmentSelector = (
    <Button
      alignContent="space-between"
      data-testid="test-proceed"
      onClick={() => setBottomSheetAttachmentOptions(true)}
      variant="secondary"
      style={{ borderColor: theme.colors.neutral.grayscale.A200 }}
    >
      <PMedium style={{ color: theme.colors.neutral.grayscale.A500 }} marginBottom="0px">
        Anexar arquivos
      </PMedium>{' '}
      {iconChevronRight}
    </Button>
  )

  const renderFiles = attachedFiles.map((item) => {
    const itsImg = item.fileExtension.includes('image/')

    return (
      <InterUIAttachmentCard
        key={item.id}
        margin="24px 0px 0px"
        status="filledSimple"
        fileName={item.name}
        subtitle={itsImg ? 'Imagem anexada' : 'Arquivo anexado'}
        imageSrc={itsImg ? item.fileContent : undefined}
        onRemove={() => onRemoveFile(item.id)}
        onError={() => setBottomSheetError(true)}
      />
    )
  })

  const stickyFooter = (
    <>
      <InterUIContainer>
        <InterUIButton onClick={onContinue}>
          {attachedFiles.length === 0 ? <p>Não quero enviar anexo</p> : <p>Continuar</p>}
        </InterUIButton>
      </InterUIContainer>
    </>
  )

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_upload_files',
      module: 'dr_inter',
      actions: ['open_files_with_medicines', 'open_files_without_medicines'],
      params: {},
    })
  }, [])

  return (
    <>
      {showCamera ? (
        <CaptureImage setShowCamera={setShowCamera} setCameraScreen={setCameraScreen} />
      ) : (
        <>
          <InterUIContainer margin="0" stickyFooter={stickyFooter}>
            <InterUIProgressBar progress="75%" />
            <InterUIContainer margin="0px 24px">
              <H1 margin="24px 0px 16px">Você gostaria de enviar algum arquivo para o médico?</H1>
              <PLarge scale={400} marginBottom="24px">
                Ex: Fotos de lesões, resultados de exames, etc.
              </PLarge>

              {attachmentSelector}

              <PSmall scale={400} margin="8px 0px 0px">
                Tamanho máximo de 10mb
              </PSmall>

              {attachedFiles.length > 0 && <InterUISeparator margin="24px 0 0" variant="small" />}

              {renderFiles}
            </InterUIContainer>
          </InterUIContainer>

          <AttachmentBS
            toggleState={[bottomSheetAttachmentOptions, setBottomSheetAttachmentOptions]}
            setBottomSheetError={setBottomSheetError}
            setBottomSheetErrorMsg={setBottomSheetErrorMsg}
            setShowCamera={setShowCamera}
          />

          <InterUIBottomSheet
            data-testid="invalid-file-bottom-sheet"
            toggle={bottomSheetError}
            onHide={() => setBottomSheetError(false)}
            title={bottomSheetErrorMsg?.message}
          >
            <Button variant="primary" margin="20px 0" onClick={() => setBottomSheetError(false)}>
              Fechar
            </Button>
          </InterUIBottomSheet>
        </>
      )}
    </>
  )
}

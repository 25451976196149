import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { CardSchedule } from 'src/components/CardSchedule/CardSchedule'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { TAGS } from 'src/enums/TaggingEnum'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { PSmall } from 'src/styles/commons'

import { InterUIAlert, InterUIContainer } from '@interco/inter-ui-react-lib'

export const ScheduledCardDetails: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const appointment = useSelector(SchedulingActions.getSelectedAppointment)

  const [isWithin24h, setIsWithin24h] = useState(false)

  const userDate = new Date()
  const SERVER_TIMEZONE = '-03:00'

  const [day, month, year, hours, minutes] = appointment.appointmentDate.split(/\/|\s|:/)
  const appointmentDate = new Date(
    `${year}-${month}-${day}T${hours}:${minutes}:00${SERVER_TIMEZONE}`,
  )

  useBasePage({
    navbarTitle: PageTitles.APPOINTMENT_SCHEDULED,
    backNavigationHandler: history.goBack,
    hideHomeBtn: true,
  })

  useEffect(() => {
    const diffInMs = appointmentDate.getTime() - userDate.getTime()

    if (diffInMs > 86400000) {
      setIsWithin24h(true)
    }
  }, [])

  const rescheduleAppointment = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CONSAGENDADA_T_OPCAO.name, {
      content_name: 'Reagendar consulta',
    })

    if (appointment.professional) {
      history.push(TypesRoutes.RESCHEDULE)
    }
  }

  const goToCancellation = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_CONSAGENDADA_T_OPCAO.name, {
      content_name: 'Cancelar consulta',
    })
    BaseBridge.ticWb({
      name: 'btn_cancel_schedule',
      module: 'dr_inter',
      action: 'cancel_schedule',
      params: {
        content_name: 'Cancelar consulta',
      },
    })

    dispatch(
      HealthcareActions.setRedirectToCancel({
        history,
        pathname: TypesRoutes.APPOINTMENT_CANCELLATION,
        type: 'SCHEDULE',
      }),
    )
  }

  const stickyFooter = (
    <>
      {isWithin24h && (
        <>
          <Button onClick={rescheduleAppointment}>Reagendar consulta</Button>
          <Button margin="16px 0 0" variant="secondary" onClick={goToCancellation}>
            Cancelar consulta
          </Button>
        </>
      )}
    </>
  )

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_scheduled_card',
      module: 'dr_inter',
      actions: ['click_detail'],
      params: {},
    })
  }, [])

  return (
    <InterUIContainer margin="24px" stickyFooter={stickyFooter}>
      <CardSchedule appointmentDetails={appointment} showStatus={!appointment.status} />

      <InterUIAlert margin="24px 0 16px">
        <PSmall marginBottom="4px" bold>
          Consideramos o horário de Brasília
        </PSmall>
        <PSmall marginBottom="0">
          Se estiver em outro fuso, siga a hora oficial em Brasília para marcar a sua consulta.
        </PSmall>
      </InterUIAlert>

      <InterUIAlert>
        <PSmall marginBottom="4px" bold>
          Surgiu um imprevisto?
        </PSmall>
        <PSmall marginBottom="0">
          Você pode cancelar ou reagendar a sua consulta com até 24 horas de antecedência. Se não
          for possível cancelar a tempo, o valor cobrado será estornado em até 24 horas.
        </PSmall>
      </InterUIAlert>
    </InterUIContainer>
  )
}

import React, { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadSpecialties } from 'src/components'
import { ExitScheduleBS } from 'src/components/ExitScheduleBS/ExitScheduleBS'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { ISpecialty } from 'src/store/ducks/scheduling/types'
import { ContainerFullPage, H3, PMedium, PSmall, SearchContainer } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIListDescription,
  InterUIProgressBar,
  RemoveAccents,
} from '@interco/inter-ui-react-lib'

export const Specialties: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const specialtiesList = useSelector(SchedulingActions.getSpecialtiesList)

  const iconSearch = <Icons.Search color={theme.colors.primary.A500} className="inter-ic-search" />
  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconDoctor = <Icons.Doctor color={theme.colors.primary.A500} />

  const searchRef = useRef<HTMLInputElement>(null)

  const [emptySearch, setEmptySearch] = useState(true)
  const [specialtiesListView, setSpecialtiesListView] = useState<ISpecialty[]>([])
  const [exitBS, setExitBS] = useState(false)

  let debounce: NodeJS.Timeout

  useBasePage({
    navbarTitle: PageTitles.APPOINTMENT_SCHEDULING,
    showExitBS: true,
    homeNavigationHandler: () => setExitBS(true),
    backNavigationHandler: history.goBack,
  })

  useEffect(() => {
    setSpecialtiesListView([...specialtiesList])
  }, [specialtiesList])

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement

    const search = RemoveAccents(value, true)

    setEmptySearch(!search)

    clearTimeout(debounce)

    debounce = setTimeout(() => {
      if (search) {
        const updatedListView = specialtiesList.filter((item) =>
          RemoveAccents(item.description, true).includes(search),
        )
        setSpecialtiesListView(updatedListView)
      } else {
        setSpecialtiesListView(specialtiesList)
      }
    }, 150)
  }

  const deleteSearchValue = () => {
    setEmptySearch(true)
    setSpecialtiesListView(specialtiesList)

    if (searchRef.current) {
      searchRef.current.value = ''
    }
  }

  const handleSelectedSpecialty = (item: ISpecialty) => {
    dispatch(SchedulingActions.setDoctorsList([]))
    dispatch(SchedulingActions.setRealizedAppointments([]))
    dispatch(SchedulingActions.setSelectedSpecialty(item))
    dispatch(
      SchedulingActions.getDoctorsListRequest({
        specialtyId: item.id,
        page: 1,
      }),
    )
    BaseBridge.ticWb({
      name: 'btn_select_specialty',
      module: 'dr_inter',
      action: 'select_specialty',
      params: {
        specialtyId: item.id,
      },
    })
    history.push(TypesRoutes.PROFESSIONAL)
  }

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_choose_specialty',
      module: 'dr_inter',
      actions: [
        'select_doctor',
        'reschedule_appointment_unrealized',
        'reschedule_appointment_cancelled',
        'schedule_appointment_cancellation_immediate',
        'schedule_appointment_cancellation',
      ],
      params: {},
    })
  }, [])

  const search = (
    <SearchContainer>
      <input
        ref={searchRef}
        data-testid="search"
        type="search"
        name="schedule-search"
        placeholder="Pesquisar"
        autoComplete="off"
        onKeyUp={handleSearch}
        aria-labelledby="schedule-search-button"
      />

      {emptySearch ? (
        iconSearch
      ) : (
        <Icons.Exit
          color={theme.colors.primary.A500}
          className="inter-ic-exit"
          data-testid="deleteSearchValue"
          onClick={deleteSearchValue}
        />
      )}
    </SearchContainer>
  )

  const renderSpecialtiesList = specialtiesListView.map((item, index) => (
    <InterUIListDescription
      key={`specialties-${index.toString()}`}
      id={`specialties-${index.toString()}`}
      data-testid={`specialties-${index.toString()}`}
      iconLeft={iconDoctor}
      iconRight={iconArrow}
      withBorder
      margin="0 0 16px"
      onClick={() => handleSelectedSpecialty(item)}
      role="group"
    >
      <PMedium marginBottom="0" bold>
        {item.description}
      </PMedium>
    </InterUIListDescription>
  ))

  return specialtiesList && specialtiesList.length > 0 ? (
    <>
      <InterUIContainer margin="0px">
        <InterUIProgressBar progress="25%" />
        <ContainerFullPage height="0px" margin="0 24px">
          <PSmall margin="24px 0 0" bold scale={300}>
            Passo 1/4 - Especialidades
          </PSmall>
          <H3 margin="24px 0">Busque por especialidade</H3>

          {search}

          {specialtiesListView.length > 0 ? (
            renderSpecialtiesList
          ) : (
            <PSmall>Nenhum especialista encontrado</PSmall>
          )}
        </ContainerFullPage>
      </InterUIContainer>

      <ExitScheduleBS toggleState={[exitBS, setExitBS]} />
    </>
  ) : (
    <ContainerFullPage height="24px" margin="24px 24px 0" overflowHidden>
      <LoadSpecialties />
    </ContainerFullPage>
  )
}

import React, { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/pageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { H1, H2, PMedium, PSmall, SuccessSign } from 'src/styles/commons'
import { numberFormat, today } from 'src/utils/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'

import { ContentTextValue } from './CancellationFineSuccess.styles'

export const CancellationFineSuccess: React.FC = () => {
  const theme = useTheme()

  const holder = useSelector(SubscriptionActions.getHolder)
  const { cancellationAmount } = useSelector(SubscriptionActions.getCancellationFee)
  const formOfPayment = useSelector(CheckoutActions.getFormOfPayment)

  const successIcon = <Icons.CheckCircle width={32} height={32} color={theme.colors.base.white} />

  useBasePage({
    navbarTitle: PageTitles.DR_INTER,
    hideHomeBtn: false,
    hideBackNavigationBtn: true,
  })

  // Data no formato 00/00
  const currentDate = today.slice(0, 5)

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button
        variant="primary"
        margin="0 0 16px"
        onClick={() => BaseBridge.openDeepLink('bancointer://seguros')}
      >
        Voltar para home de seguros
      </Button>

      {false && <Button variant="secondary">Compartilhar</Button>}
    </InterUIContainer>
  )

  useEffect(() => {
    BaseBridge.tacWb({
      name: 'screen_cancellation_fine_success',
      module: 'dr_inter',
      actions: ['status_processed'],
      params: {},
    })
  }, [])

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <InterUIContainer margin="40px 24px 32px">
        <SuccessSign>{successIcon}</SuccessSign>
        <H1 margin="40px 0 8px">Cancelamento realizado!</H1>
        <PMedium scale={400} marginBottom="0">
          O cancelamento da sua assinatura do Doutor Inter foi realizado com sucesso.
        </PMedium>
      </InterUIContainer>

      <InterUISeparator />

      <InterUIContainer margin="32px 24px 32px">
        <H2 margin="42px 0 16px">Dados da minha assinatura</H2>
        <PMedium scale={400} marginBottom="24px">
          Olá, {holder.name}! Esses são os dados da sua assinatura:
        </PMedium>
        <ContentTextValue>
          <PSmall scale={400} marginBottom="0px">
            Titular
          </PSmall>
          <PSmall bold marginBottom="0px">
            {holder.name}
          </PSmall>
        </ContentTextValue>
        <ContentTextValue>
          <PSmall scale={400} marginBottom="0px">
            Forma de pagamento
          </PSmall>
          <PSmall bold marginBottom="0px">
            {formOfPayment.type === 'DEBIT'
              ? formOfPayment.title
              : `${formOfPayment.title} - ${formOfPayment?.subTitle?.replace('****', '')}`}
          </PSmall>
        </ContentTextValue>
        <ContentTextValue>
          <PSmall scale={400} marginBottom="0px">
            Taxa de cancelamento
          </PSmall>
          <PSmall bold marginBottom="0px">
            {numberFormat(Number(cancellationAmount))}
          </PSmall>
        </ContentTextValue>
        <ContentTextValue>
          <PSmall scale={400} marginBottom="0px">
            Data de cancelamento
          </PSmall>
          <PSmall bold marginBottom="0px">
            {currentDate}
          </PSmall>
        </ContentTextValue>
        <ContentTextValue>
          <PSmall scale={400} marginBottom="0px">
            Status da assinatura
          </PSmall>
          <PSmall bold marginBottom="0px">
            Cancelado
          </PSmall>
        </ContentTextValue>
      </InterUIContainer>
      <InterUISeparator />
    </InterUIContainer>
  )
}

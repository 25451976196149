export const reasonsToHire = [
  {
    label: 'Fácil de buscar e agendar',
    value:
      'Escolha especialistas com quem quer agendar sua vídeoconsulta e qual o melhor horário pra você.',
  },
  {
    label: 'Lembrete da hora da consulta',
    value: 'Receba alertas por e-mail ou pelo app pra não perder seu horário.',
  },
  {
    label: 'Atestados, receitas e solicitações digitais',
    value: 'Receitas, pedidos de exame e emissão de atestados 100% digitais.',
  },
  {
    label: 'Videoconsulta com clínico geral',
    value: 'Videoconsulta de pronto atendimento com clínico geral, por apenas R$ 50,90.',
  },
  {
    label: 'Videoconsulta com especialista',
    value: 'Videoconsulta agendada com profissionais de saúde especializados, por apenas R$ 77,00.',
  },
  {
    label: 'Flexibilidade no pagamento',
    value: 'Escolher a melhor opção para pagar as suas consultas, com cartão de débito ou crédito.',
  },
]

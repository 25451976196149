import React, { lazy, Suspense, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Babi, SymptomsBS } from 'src/components'
import { CommonQuestions } from 'src/components/CommonQuestions/CommonQuestions'
import { LogoConexa } from 'src/components/LogoConexa/LogoConexa'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { ErrorIcons } from 'src/enums/ErrorIcons'
import PDFUrlDictionary from 'src/enums/PdfUrlDictionary'
import { TAGS } from 'src/enums/TaggingEnum'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { IFormOfPayment } from 'src/store/ducks/checkout/types'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { ErrorDetails } from 'src/store/ducks/error/types'
import { HealthcareActions } from 'src/store/ducks/healthcare/actions'
import { SchedulingActions } from 'src/store/ducks/scheduling/actions'
import { SpecialtiesActions } from 'src/store/ducks/specialties/actions'
import { SubscriptionActions } from 'src/store/ducks/subscription/actions'
import { TabActions } from 'src/store/ducks/tab/actions'
import { H3, PMedium, PSmall } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import {
  InterUIContainer,
  InterUIListDescription,
  InterUILoading,
  InterUISeparator,
  InterUITag,
} from '@interco/inter-ui-react-lib'

export const Emergency: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const spontaneousDemandsList = useSelector(SpecialtiesActions.getSpontaneousDemandsList)
  const highlightList = useSelector(SpecialtiesActions.getHighlightsList)
  const attendanceStatus = useSelector(HealthcareActions.getAttendanceStatus)
  const holder = useSelector(SubscriptionActions.getHolder)
  const selectedTab = useSelector(TabActions.get)

  const iconArrow = <Icons.ChevronRight color={theme.colors.primary.A500} />
  const iconLocation = <Icons.Location color={theme.colors.primary.A500} />

  const importIcon = (iconName: string) =>
    lazy(() => import(`@interco/inter-frontend-svgs/lib/orangeds/MD/${iconName}.js`))

  const [toggleBS, setToggleBS] = useState(false)

  useEffect(() => {
    dispatch(TabActions.clinic())
    dispatch(CheckoutActions.setFormOfPayment({} as IFormOfPayment))
  }, [dispatch])

  const requestPermissions = async () => {
    const response = await BaseBridge.requestVideoCallPermissions()
    let errorDevice: false | JSX.Element = false

    if (response.audio === 'ERROR' && response.video === 'ERROR') {
      errorDevice = (
        <span>
          <strong>áudio</strong> e o <strong>vídeo</strong>
        </span>
      )
    } else if (response.audio === 'ERROR') {
      errorDevice = <strong>áudio</strong>
    } else if (response.video === 'ERROR') {
      errorDevice = <strong>vídeo</strong>
    }

    if (errorDevice) {
      const errorDetails: ErrorDetails = {
        title: 'Conceda as permissões para começar a consulta',
        subTitle: (
          <>
            Vá até as configurações do seu dispositivo e permita que o app do Inter acesse o{' '}
            {errorDevice} do seu celular.
          </>
        ),
        icon: ErrorIcons.CAMERA,
      }
      dispatch(ErrorActions.show(errorDetails))
    }

    return response
  }

  const onContinue = () => {
    requestPermissions().then(({ audio, video }) => {
      if (audio && video !== 'ERROR') {
        BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_T_CLINICAGERAL.name, {
          content_id: selectedTab,
        })
        if (attendanceStatus === 'PENDING') {
          dispatch(
            SchedulingActions.setAppointmentType({
              history,
              pathname: TypesRoutes.WAITING_ROOM,
              type: 'IMMEDIATE',
            }),
          )
        } else {
          setToggleBS(true)
        }
      }
    })
  }

  const renderSpontaneousDemandsStatus = (price: string) => {
    let fill: React.ReactElement

    switch (attendanceStatus) {
      case 'FREE':
        fill = (
          <InterUITag variant="success" margin="4px 0 8px">
            Primeira consulta por nossa conta
          </InterUITag>
        )
        break
      case 'PENDING':
        fill = (
          <InterUITag backgroundColor={theme.colors.primary.A500} margin="4px 0 8px">
            Voltar para a fila de espera
          </InterUITag>
        )
        break
      default:
        fill = (
          <PSmall marginBottom="0px" bold scale={400}>
            {`Por apenas ${price}`}
          </PSmall>
        )
        break
    }

    return fill
  }

  const renderSpontaneousDemandsList = spontaneousDemandsList.map((item, index) => {
    const key = `spontaneous-${index}`
    const Icon = importIcon(item.icon)

    const iconSpontaneousDemands = (
      <Suspense fallback={<InterUILoading size="md" />}>
        <Icon color={theme.colors.primary.A500} />
      </Suspense>
    )

    return (
      <InterUIListDescription
        key={key}
        id={key}
        data-testid={key}
        margin="16px 0 0"
        iconLeft={iconSpontaneousDemands}
        iconRight={iconArrow}
        withBorder
        onClick={onContinue}
        role="group"
      >
        <PMedium marginBottom="0px" bold>
          Pronto atendimento
        </PMedium>
        <PMedium marginBottom="4px" bold scale={400}>
          {item.name}
        </PMedium>

        {renderSpontaneousDemandsStatus(item.price)}
      </InterUIListDescription>
    )
  })

  const renderSpecialtiesList = highlightList.map((item, index) => {
    const key = `highlights-${index}`
    const Icon = importIcon(item.icon)

    const iconSpecialties = (
      <Suspense fallback={<InterUILoading size="md" />}>
        <Icon color={theme.colors.primary.A500} />
      </Suspense>
    )

    return (
      <InterUIListDescription
        key={key}
        id={key}
        data-testid={key}
        iconLeft={iconSpecialties}
        iconRight={iconArrow}
        withBorder
        margin="16px 0 0"
        onClick={() => handleClickListSpecialties(item.route)}
        role="group"
      >
        <PMedium marginBottom="4px" bold>
          {item.name}
        </PMedium>
        <PSmall marginBottom="0px" bold scale={400}>
          {`${item.price} por consulta`}
        </PSmall>
      </InterUIListDescription>
    )
  })

  /**
   * Controla a navegação dos fluxos da consulta.
   * @param route rotas de navegação da consulta,
   * /specialty/doctor - Escolha especialidades Médicas,
   * /specialty/psychologist - Agendamento com Psicólogo,
   * /specialty/nutritionist - Agendamento com Nutricionista
   */
  const handleClickListSpecialties = (route: string) => {
    requestPermissions().then(({ audio, video }) => {
      if (audio && video !== 'ERROR') {
        switch (route) {
          case '/specialty/doctor':
            BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_T_AGENDARCONSULTA.name, {
              content_name: 'Médicos',
            })
            dispatch(SchedulingActions.getSpecialtiesListRequest())
            BaseBridge.ticWb({
              name: 'btn_select_doctor',
              module: 'dr_inter',
              action: 'select_doctor',
              params: {
                type: 'DOCTOR',
                id: holder.patientId,
              },
            })
            dispatch(
              SchedulingActions.setProfessionalType({
                history,
                pathname: TypesRoutes.SPECIALTIES,
                type: 'DOCTOR',
              }),
            )

            break
          case '/specialty/psychologist':
            BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_T_AGENDARCONSULTA.name, {
              content_name: 'Psicólogos',
            })

            if (holder.patientId) {
              BaseBridge.ticWb({
                name: 'btn_select_psychologist',
                module: 'dr_inter',
                action: 'select_psychologist',
                params: {
                  type: 'PSYCHOLOGIST',
                  id: holder.patientId,
                },
              })
              dispatch(
                SchedulingActions.getProfessionalsListRequest({
                  type: 'PSYCHOLOGIST',
                  page: 1,
                  patientId: holder.patientId,
                }),
              )

              dispatch(
                SchedulingActions.setProfessionalType({
                  history,
                  pathname: TypesRoutes.PROFESSIONAL,
                  type: 'PSYCHOLOGIST',
                }),
              )
            }

            break
          case '/specialty/nutritionist':
            BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_HOME_T_AGENDARCONSULTA.name, {
              content_name: 'Nutricionistas',
            })

            if (holder.patientId) {
              BaseBridge.ticWb({
                name: 'btn_select_nutritionist',
                module: 'dr_inter',
                action: 'select_nutritionist',
                params: {
                  type: 'NUTRITIONIST',
                  id: holder.patientId,
                },
              })
              dispatch(
                SchedulingActions.getProfessionalsListRequest({
                  type: 'NUTRITIONIST',
                  page: 1,
                  patientId: holder.patientId,
                }),
              )

              dispatch(
                SchedulingActions.setProfessionalType({
                  history,
                  pathname: TypesRoutes.PROFESSIONAL,
                  type: 'NUTRITIONIST',
                }),
              )
            }

            break
          default:
            break
        }
      }
    })
  }

  const goToProductManual = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_REDE_CONVENIADA_T_MANUAL.name)
    BaseBridge.openPdf(PDFUrlDictionary.MANUAL, 'Manual do Doutor Inter')
  }

  return (
    <>
      <InterUIContainer margin="0">
        <InterUIContainer margin="0 24px 32px">
          <H3 marginBottom="8px">Consultar agora</H3>
          <PMedium marginBottom="0" scale={400}>
            Consulta com um médico por video para o titular do plano.
          </PMedium>

          {spontaneousDemandsList &&
            spontaneousDemandsList.length > 0 &&
            renderSpontaneousDemandsList}
        </InterUIContainer>

        <InterUISeparator height="8px" />

        <InterUIContainer margin="32px 24px">
          <H3 marginBottom="16px">Agendar consulta</H3>

          {highlightList && highlightList.length > 0 && renderSpecialtiesList}
        </InterUIContainer>

        <InterUISeparator height="8px" />

        <InterUIContainer margin="32px 24px">
          <H3 marginBottom="16px">Rede conveniada</H3>

          <InterUIListDescription
            data-testid="test-product-manual"
            iconLeft={iconLocation}
            iconRight={iconArrow}
            withBorder
            onClick={goToProductManual}
            role="group"
          >
            <PMedium marginBottom="0px" bold>
              Farmácias e Laboratórios
            </PMedium>
          </InterUIListDescription>
        </InterUIContainer>

        <InterUISeparator height="8px" />

        <InterUIContainer margin="32px 24px 24px">
          <CommonQuestions typePage="Home" />
        </InterUIContainer>

        <InterUIContainer margin="0 0 36px">
          <LogoConexa />
        </InterUIContainer>

        <InterUIContainer margin="0 24px">
          <Babi />
        </InterUIContainer>
      </InterUIContainer>

      <SymptomsBS toggleState={[toggleBS, setToggleBS]} />
    </>
  )
}

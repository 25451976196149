import React, { Dispatch, SetStateAction } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TAGS } from 'src/enums/TaggingEnum'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { FilesActions } from 'src/store/ducks/attachFiles/actions'
import { MedicinesActions } from 'src/store/ducks/medicines/actions'
import { SymptomsActions } from 'src/store/ducks/symptoms/actions'
import { PSmall } from 'src/styles/commons'

import { InterUIBottomSheet } from '@interco/inter-ui-react-lib'

import { Button } from '..'

interface SymptomsBSProps {
  toggleState: [boolean, Dispatch<SetStateAction<boolean>>]
}

export const SymptomsBS: React.FC<SymptomsBSProps> = ({ toggleState }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [toggle, setToggle] = toggleState

  const clearSelectedMedicinesFiles = () => {
    dispatch(MedicinesActions.setSelectedMedicines([]))
    dispatch(FilesActions.setSelectedFilesAttached({ files: [] }))
  }

  const goToSymptoms = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_SINTOMA_T_INFORMARSINTOMA.name, {
      content_name: 'Sim, gostaria',
    })
    BaseBridge.ticWb({
      name: 'btn_consult_symptoms',
      module: 'dr_inter',
      action: 'open_consult_symptoms_page',
      params: {
        content_name: 'Sim, gostaria',
      },
    })
    dispatch(SymptomsActions.getSymptomsListRequest())
    clearSelectedMedicinesFiles()
    history.push(TypesRoutes.SYMPTOMS)
  }

  const goToMedicines = () => {
    BaseBridge.requestAnalytics(TAGS.C_DOUTORINTER_SINTOMA_T_INFORMARSINTOMA.name, {
      content_name: 'Não, obrigado',
    })
    BaseBridge.ticWb({
      name: 'btn_consult_medicines',
      module: 'dr_inter',
      action: 'open_consult_medicines_page',
      params: {
        content_name: 'Não, obrigado',
      },
    })
    clearSelectedMedicinesFiles()
    history.push(TypesRoutes.MEDICINES)
  }

  return (
    <InterUIBottomSheet
      title="Gostaria de informar algum sintoma?"
      toggle={toggle}
      onHide={() => setToggle(false)}
      data-testid="symptoms-BS"
    >
      <PSmall scale={400} marginBottom="0">
        A seguir, você passará por uma breve triagem. Os dados de saúde fornecidos serão coletados
        pelo Inter e tratados pelo parceiro Conexa Saúde.
      </PSmall>
      <Button margin="16px 0 0" onClick={goToSymptoms}>
        Sim, gostaria
      </Button>
      <Button margin="16px 0 0" variant="secondary" onClick={goToMedicines}>
        Não, obrigado
      </Button>
    </InterUIBottomSheet>
  )
}
